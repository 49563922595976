import fetchFavoriteItems from "../business_logics/fetchFavoriteItems";

const handleCount = () => {
  const favRoomIds = fetchFavoriteItems();
  const count = favRoomIds.length;
  const nodes = document.querySelectorAll("*[data-favorite-count]");
  // for IE
  const elements: HTMLDivElement[] = Array.prototype.slice.call(nodes, 0);
  elements.forEach(el => {
    const html = `<span class='tag is-rounded is-yellow'>${count}</span>`;
    if (count > 0) {
      el.innerHTML = html;
      el.style.display = "block";
    } else {
      el.style.display = "none";
    }
  });
};
handleCount();
window.addEventListener("jfo/update-fav-item-count", handleCount, false);
